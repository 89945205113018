
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import type { TextArray, StatusType } from '@/definitions/shared/types'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'

export default defineComponent({
  components: {
    TmColoredUl,
    TmStylizedText,
    TmModal,
    TmButton,
    TmFormLine,
  },
  props: {
    placeholder: {
      type: String,
    },
    description: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
    btnText: {
      type: String,
      default: 'Save',
    },
    btnColor: {
      type: String,
      default: 'blue',
    },
    label: {
      type: String,
    },
    list: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    options: {
      type: Array as PropType<StatusType[]>,
      required: true,
    },
  },
  setup(props) {
    const val = ref<StatusType>(props.options[0])

    return {
      val,
    }
  },
})
